





















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'UserNotEnabledApi',
  components: {},
  props: {},
  setup() {
    return {}
  }
})
